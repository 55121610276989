body{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #080b27;
  margin: 0;
  padding: 0;}
.sizing {
  height: 260px;}
.slider {
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 200px;
  align-items: center;
  display: flex;
	margin: auto;
	overflow: hidden;
	position: relative;
  border-top: 2px solid #36384f;
  border-bottom: 2px solid #36384f;}
.slider::before,
.slider::after {
	content: "";
	height: 200px;
	position: absolute;
	width: 200px;
	z-index: 2;}
.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);}
.slider::before {
	left: 0;
	top: 0;}
.slide-track {
	animation: scroll 40s linear infinite;
	width: calc(250px * 14);}
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7)); }}
html {
  scroll-behavior: smooth;}
::-webkit-scrollbar {
  width: 1.3rem;;}
::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;}
::-webkit-scrollbar-thumb:hover {
  background: #222224;}
::-webkit-scrollbar-track {
  background: #f9f9f9;}
.swiper_container {
  height: 52rem;

  }
.swiper-slide {
  width: 100%;
  height: 42rem;
  }
@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;}
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;}
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;}}
.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 1rem;
  object-fit: cover;}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;}
.slider-controler {
  position: relative;
  bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;}
.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;}
@media (max-width: 990px) {.slider-controler .swiper-button-next {left: 70% !important;}}
@media (max-width: 450px) {.slider-controler .swiper-button-next {left: 80% !important;}}
@media (max-width: 990px) {.slider-controler .swiper-button-prev {left: 30% !important;}}
@media (max-width: 450px) {.slider-controler .swiper-button-prev {left: 20% !important;}}
    
.slider-controler .slider-arrow {
    background-image:linear-gradient(to right, rgb(80, 129, 161), rgb(157, 55, 129));;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    padding: 30px;
    margin-bottom: 30px;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));}